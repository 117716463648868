.bg-login {
    background: no-repeat url('../../../assets/images/bg/droppity_bg_web_login.webp');
    background-size: 100% 100%;
    @media(max-width: 1200px) {
        background-size: cover;
    }
}
.sidebar-logo {
    max-height: 140px;
    max-width: 140px;
}
.social-button img {
    min-height: 24px;
    min-width: 24px;
}
.social-button, .social-button:hover, .social-button:active, .social-button:focus {
    background: no-repeat center #000000;
    border-radius: 12px;
    height: 50px;
}
.store-img {
    height: auto;
    max-height:60px;
}
.button_text {
    color: #E6E6E6;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.0357em;
    font-size: 16px;
    line-height: 20px;
}
