@import "../mixins/mixin_reuse";

.dInput {
    color: white;
    width: 100%;
    padding: 12px 16px;
    border: none;
    outline: none;
    border-radius: 12px;
    line-height: 24px;
    @include fs($common, $common-mobile);
    &-ph-white {
        &::placeholder {
            display: inline;
            color: white;
            opacity: 1;
        }
            
        &:-ms-input-placeholder {
            color: white;
        }
        
        &::-ms-input-placeholder {
            color: white;
        }
    }
    &-focus {
        animation: pulse-border 3s infinite;
    }
    &-container {
        margin: 0 2px;
    }
    &-border {
        padding: 10px 14px;
        border: 2px solid $droppity-yellow;
    }
    &-item {
        @include bg($droppity-item-bg);
    }
    &-main {
        @include bg($droppity-main-bg);
    }
}

@keyframes pulse-border {
    0% {
        outline: 2px solid $droppity-main-bg;
    }
    50% {
        outline: 2px solid $droppity-yellow;
    }
    100% {
        outline: 2px solid $droppity-main-bg;
    }
}

.dInput[contenteditable]:empty::before {
    content: attr(data-content);
    color: gray;
}
