.list-item-overflow {
  overflow: hidden;
  white-space: normal;
  // text-overflow: ellipsis;
  // &:active {
  //   overflow: auto;
  //   white-space: normal;
  // }
}

.device_details_list__item {
  padding: 12px 0 0 0 !important;
  border: none !important;
  display: flex !important;
  justify-content: space-between;
  @include bg($droppity-item-bg);
  @media (max-width: 768px) {
    flex-direction: column !important;
  }
  &:first-child {
    padding: 0 !important;
  }
  &:last-child {
    padding: 12px 0 12px 0 !important;
  }
}
