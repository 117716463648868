@import "./mixins/mixin_reuse";
.mobile-none {
    @media(max-width: 576px) {
        display: none;
    }
}
.of-hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fs-heavy {
    @include fs($heavy, $heavy-mobile);
}
.fs-large {
    @include fs($large, $large-mobile);
}
.fs-header {
    @include fs($header, $header-mobile);
}
.fs-common {
    @include fs($common, $common-mobile);
}
.fs-small {
    @include fs($small, $small-mobile);
}
.fs-tiny {
    @include fs($tiny, $tiny-mobile);
}
.split-line {
    color: gray;
}
.hidden {
    display: none;
  }
a {
    color: $white;
    &:hover { color: $white; }
    &::after { color: $white; }
}
div {
    color: $white;
}
div .text-secondary {
    color: $secondary;
}
.precip-color {
    color: $precip;
}
.high-param {
    color: $high-param;
}
.low-param {
    color: $low-param;
}
.bg-dark {
    @include bg($droppity-main-bg);
}
.bg-dark__item {
    @include bg($droppity-item-bg);
}
.bg-dark__yellow {
    @include bg($droppity-yellow);
}
.item-color-white {
    color: $white;
}
.item-color-gray {
    color: #878792;
}
#sidebar-border {
    border-right: 2px solid $droppity-main-bg;
    @media(max-width: 576px) {
        border-right: none;
    }
}
#header-border {
    border-top: 2px solid $droppity-item-bg !important;
    border-bottom: 2px solid $droppity-main-bg !important;
}
#footer-border {
    border-top: 2px solid $droppity-main-bg !important;
}
.btn-droppity {
    @include bg($droppity-item-bg);
    color: $droppity-yellow;
    border: none;
    &:hover {
        @include bg($droppity-active);
        color: white;
    }
}
.br-12 {
    border-radius: 12px;
}
html.is-locked, .ReactModal__Body--open {
    overflow: hidden;
    position: relative;
    touch-action: none;
    -ms-touch-action: none;
}
.btn-droppity_active {
    @include bg($droppity-active);
    color: white;
}
.droppity-hover-yellow {
    &:hover {
        color: $droppity-yellow
    }
}
.btn-lang {
    background-color: rgba(0,0,0,0);
    border-radius: 12px;
    color: white;
    border: none;
}
.btn-lang_active {
    background-color: rgba(0,0,0,0);
    border-radius: 12px;
    color: white;
    border: none;
    text-decoration: underline;
    -webkit-text-decoration-color: $droppity-yellow; 
    text-decoration-color: $droppity-yellow;
    text-decoration-thickness: 2px;
}
.btn-card {
    font-size: 18px;
    line-height: 24px;
    border-top: 1px solid rgba(255, 255, 255, .3);
    border-right: 1px solid rgba(255, 255, 255, .3);
    &:first-child {
        border-radius: 0 0 0 12px;
    }
    &:last-child {
        border-radius: 0 0 12px 0;
        border-right: none;
    &:only-child {
        border-radius: 0 0 12px 12px;
        border-right: none;
    }
    }
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block !important;
    width: auto !important;
}
.dP {
    border-radius: 12px;
}
.yellow {
    color: $droppity-yellow;
}
.text-yellow-bright {
    color: $text-yellow-bright;
}
.nav-link {
    &.active {
        border-right: 2px solid $droppity-yellow;
    }
}

.modal-bg {
    background-color: rgba(0, 0, 0, 0.8);
}

.image-gallery-thumbnail-image {
    object-fit: contain;
}

.selected-card {
    border: 2px solid $droppity-yellow;
}

.close {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
    opacity: 0.8;
    z-index: 4;
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {
    position: absolute;
    right: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: white;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

* {
    &::-webkit-scrollbar {
        width: 0px;
    }
    &::-webkit-scrollbar-track {
        background: $droppity-item-bg;
        border-radius: 50%;
        visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
        background: $droppity-yellow;
        border-radius: 50%;
        visibility: hidden;
    }
}

.popup-bubble {
    @include fs($small, $small-mobile);
    /* Position the bubble centred-above its parent. */
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the bubble. */
    background-color: $droppity-item-bg;
    padding: 3px;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 60px;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
    max-width: 200px;
}

.bubble-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popup-bubble-anchor {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $droppity-item-bg;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
    cursor: pointer;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
}

.device-popup-bubble {
    @include fs($small, $small-mobile);
    /* Position the bubble centred-above its parent. */
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the bubble. */
    background-color: $droppity-item-bg;
    padding: 3px;
    border-radius: 5px;
    overflow-y: auto;
    height: auto;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.device-popup-container {
    cursor: pointer;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 100%;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.marker {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the bubble. */
    overflow-y: auto;
    height: 60px;
}

.marker-note-image {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -142%);
    /* Style the bubble. */
    overflow-y: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.marker-anchor {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}

/* This element draws the tip. */
.marker-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
}

/* JavaScript will position this div at the bottom of the popup tip. */
.marker-container {
    cursor: pointer;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
}

.click-overlay {
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    height: 325px;
    @media(max-width: 768px) {
        width: 200px;
        height: 105px;
    }
}

.click-overlay__marker {
    animation: click-overlay__marker 2s ease infinite;
    width: 300px;
    height: 300px;
    margin-bottom: 5px;
    text-align: center;
    @media(max-width: 768px) {
        width: 80px;
        height: 80px;
    }
}

@keyframes click-overlay__marker {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.click-overlay__text {
    line-height: 20px;
    @include fs($common, $common-mobile);
}

.loading-overlay {
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 200px;
    @media(max-width: 768px) {
        width: 80px;
        height: 80px;
    }
}

.loading-overlay__spiner {
    width: 100%;
    height: 100%;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    align-items: center;
    animation: rotation 1s linear infinite;
}

.image-loading-overlay {
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    height: 10%;
    @media(max-width: 768px) {
        width: 10%;
        height: 10%;
    }
}

.image-loading-overlay__spiner {
    z-index: 1;
    aspect-ratio: 1/1;
    width: 10%;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    align-items: center;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

.welcome {
    font-family: 'Roboto';
    font-weight: 500;
    &__header {
        color: white;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.0357em;
    }
    &__text {
        color: #999999;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.0357em;
    }
}

.add-field-card, .add-device-card {
    position: relative;
    &_icon {
        z-index: 200;
        background-image: url("../../assets/icons/add_field_back.svg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    &_link {
        padding: 14px 16px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        border-radius: 0px 0px 12px 12px;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0357em;
        text-transform: uppercase;
    }
    &_text {
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 16 / 9;
        z-index: 300;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.0357143em;
        color: #20202F;
        margin: 0;
    }
}

.add-field-card {
    background-color: $droppity-yellow;
    &_link {
        background: #8F6600;
    }
}

.add-device-card {
    background-color: #6CAE75;
    &_link {
        background: #488450;
    }
}

.update_freq_sec__radio input[type="radio"] {
    &:checked {
        background-color: $droppity-yellow !important;
        border-color: $droppity-yellow !important;
    }
}

.tooltip {
    z-index: 10001;
}

.channels-list-item {
    border-radius: 12px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 420px) {
        flex-wrap: wrap;
        align-items: start;
        padding: 0 8px;
    }
    &__zone {
        @media(max-width: 420px) {
            padding: 8px 0;
        }
    }
    &__button {
        flex-shrink: 0;
        margin-left: 16px;
        @media(max-width: 420px) {
            margin: 8px 0 8px 0;
        }
    }
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.refresh-btn {
    &:hover {
        background-color: $droppity-item-bg !important; 
    }
}
