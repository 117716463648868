.form-check {
  & > input {
    height: 1.25rem !important;
    width: 1.25rem !important;
    background-color: $white !important;
    border: solid 1px #c5d3d9 !important;
    &[type='radio'] {
      margin-top: 3px;
    }
    &:hover {
      box-shadow: 0 0 0 0 #eaf1f3;
    }
    &:checked {
      background-color: $droppity-yellow !important;
      border: solid 1px $droppity-yellow !important;
      &[type='radio'] {
        margin-top: 3px;
        background-color: $white !important;
        background-image: var(
          --cui-form-check-radio-checked-bg-image,
          url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-3 -3 6 6%27%3e%3ccircle r=%272%27 fill=%27rgba%2828, 162, 231, 1%29%27/%3e%3c/svg%3e')
        );
      }
    }
    &:focus {
      background-color: $white !important;
      border: solid 1px #c5d3d9 !important;
      box-shadow: 0 0 0 0 #eaf1f3;
      &:checked {
        background-color: $droppity-yellow !important;
        border: solid 1px $droppity-yellow !important;
        &[type='radio'] {
          margin-top: 3px;
          background-color: $white !important;
          background-image: var(
            --cui-form-check-radio-checked-bg-image,
            url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-3 -3 6 6%27%3e%3ccircle r=%272%27 fill=%27rgba%2828, 162, 231, 1%29%27/%3e%3c/svg%3e')
          );
        }
      }
    }
  }
  & > label {
    // padding-top: 0.25rem;
    margin-left: 0.5rem;
    color: white;
    font-size: 1.125rem;
    line-height: 1.25rem;
    @media(max-width: 1401px) {
      font-size: 1rem;
      line-height: 1.125rem;
    }
  }
  &.form-switch {
    & > input {
      height: 1.25rem !important;
      width: 2.25rem !important;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
      background-color: #c5d3d9 !important;
      border-color: #c5d3d9 !important;
      &:checked {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
        background-color: $droppity-yellow !important;
        border-color: $droppity-yellow !important;
      }
      &:focus {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
        background-color: #c5d3d9 !important;
        border-color: #c5d3d9 !important;
        box-shadow: none !important;
        &:checked {
          background-color: $droppity-yellow !important;
          border-color: $droppity-yellow !important;
        }
      }
    }
  }
}

.form-checkbox {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-row-gap: 0.5rem;
  &__label {
    &__wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: auto;
      grid-auto-columns: auto;
      grid-column-gap: 1rem;
      justify-content: space-between;
      align-items: center;
    }
    &__text {
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: white;
      &.required:after {
        content: '*';
        color: red;
      }
      @media(max-width: 1401px) {
        font-size: 1rem;
        line-height: 1.125rem;
    }
    }
    &__tooltip {
      position: relative;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-self: flex-end;
      &_content {
        width: 20rem;
        overflow: hidden;
        visibility: hidden;
        background-color: #6e2dd5;
        color: #fff;
        text-align: center;
        padding: 1rem 1rem;
        border-radius: 5px;
        position: absolute;
        bottom: 140%;
        left: -3.5rem;
        // transform: translate(-50%);
        z-index: 1;
        &::after {
          content: ' ';
          position: absolute;
          top: 100%; /* At the bottom of the tooltip */
          left: 4rem;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #6e2dd5 transparent transparent transparent;
        }
      }
      &_icon[data-tooltip-type='dim'] {
        opacity: 0.3;
      }
      &:hover {
        & > .form-checkbox__label__tooltip_content {
          visibility: visible;
        }
      }
    }
  }
  & > input {
    height: 1.25rem;
    width: 1.25rem;
    margin-top: 0;
    &:hover {
      box-shadow: 0 0 0 0 #eaf1f3;
    }
    &:checked {
      background-color: $droppity-yellow !important;
      border: solid 1px $droppity-yellow !important;
    }
    &:focus {
      background-color: $white !important;
      border: solid 1px #c5d3d9 !important;
      box-shadow: 0 0 0 0 #eaf1f3;
      &:checked {
        background-color: $droppity-yellow !important;
        border: solid 1px $droppity-yellow !important;
      }
    }
  }
  & > .form-check {
    display: flex;
    align-items: flex-end;
  }
}
