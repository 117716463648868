.close-btn {
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
.close-btn-stick__one, .close-btn-stick__two {
  width: 2px;
}
.close-btn-stick__one {
  transform: rotate(45deg);
} 
.close-btn-stick__two {
  transform: rotate(-90deg);
}
