@import "../mixins/mixin_reuse";

.dSelect {
  display: flex;
  padding: 12px !important;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 12px;
  line-height: 24px;
  background-color: $droppity-item-bg;
  color: white;
  cursor: pointer;
  border: none;
  @include fs($common, $common-mobile);
  &-main {
    background-color: $droppity-main-bg;
  }
  &:focus {
    border: none ;
    box-shadow: none;
    outline: 0;
  }
}
