.alerts-configuration-modal {
  & .modal-content {
    @include bg($droppity-item-bg);
    border-radius: 12px;
  }
  &__checkboxes {
    padding-top: 1rem;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-row-gap: 1rem;
  }
  &__checkbox {
    &_container {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: auto;
      grid-row-gap: 1rem;
    }
  }
}
