@import "../mixins/mixin_reuse";

.dTextArea {
    resize: none;
    color: white;
    width: 100%;
    min-height: 96px;
    padding: 12px 16px;
    border: none;
    outline: none;
    border-radius: 12px;
    line-height: 24px;
    @include fs($common, $common-mobile);
    @include bg($droppity-item-bg);
    &::placeholder {
        display: inline;
        color: #878792;
        opacity: 1;
    }
        
    &:-ms-input-placeholder {
        color: #878792;
    }
    
    &::-ms-input-placeholder {
        color: #878792;
    }
    &-ph-white {
        &::placeholder {
            display: inline;
            color: white;
            opacity: 1;
        }
            
        &:-ms-input-placeholder {
            color: white;
        }
        
        &::-ms-input-placeholder {
            color: white;
        }
    }
}

.dTextArea[contenteditable]:empty::before {
    content: attr(data-content);
    color: gray;
}
