.droppity-alert {
  min-width: 200px;
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $droppity-item-bg;
  border: 2px solid $droppity-yellow;
  z-index: 1000000;
}
