@import "../mixins/mixin_reuse";

.dBackButton {
    display: flex;
    padding: 12px 8px;
    align-items: center;
    text-decoration: none;
    border-radius: 12px;
    line-height: 24px;
    @include fs($common, $common-mobile);
    @include droppity-button($droppity-yellow, $droppity-item-bg, $droppity-yellow);
    &-yellow {
        @include droppity-button(white, $droppity-yellow, $droppity-yellow);
    }
    &-sm {
        padding: 4px;
        line-height: 20px;
        @include fs($tiny, $tiny-mobile);
    }
    &_text {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
