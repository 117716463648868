.update-device-param-modal {
  border: none;
  position: absolute;
  z-index: 10000;
  border-radius: 12px;
  min-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $droppity-item-bg;
  @media (max-width: 576px) {
    top: 50%;
    left: 2%;
    transform: translate(-2%, -50%);
    width: 96%;
    min-width: 200px;
  }
  &:focus, ::before, ::after, :active {
    outline: none;
  }
  outline: none;
}

.update-device-param-modal-overlay {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(0, 0, 0, 0.5);
}
