@mixin fs($fs, $fs-mobile) {
    font-size: $fs;
    line-height: calc($fs + 6px);
    @media(max-width: 1401px) {
        font-size: $fs-mobile;
        line-height: calc($fs-mobile + 6px);
    }
}
@mixin bg($bg-color) {
    background-color: $bg-color !important;
}
@mixin droppity-button ($color, $bg-color, $bg-color_hover) {
    @include bg($bg-color);
    color: $color;
    border: none;
    cursor: pointer;
    &:hover {
        @include bg($bg-color_hover);
        color: white;
    }
}
