@import '../mixins/mixin_fielddetails';

#gray {
    background-color: $droppity-item-bg;
}
.chart-canvas-frame {
    height: 250px;
}
.cursor-panel {
    cursor: default;
}
.cursor-tab {
    cursor: pointer;
}
.bg-gradient{
    mask-image: linear-gradient(to top, #20202F 0%, rgba(32, 32, 47, 0.7) 26.12%, rgba(255, 255, 255, 0) 100%);
    -webkit-mask-image: linear-gradient(to top, #20202F 0%, rgba(32, 32, 47, 0.7) 26.12%, rgba(255, 255, 255, 0) 100%);
}
.progress {
    background-color: $droppity-item-bg;
}
.progress-bar-item {
    border-right: 1px solid white;
    &:last-child {
        border-right: none;
    }
}
.block-success {
    background-color: #78CD51;
}
.block-warning {
    background-color: #F5B700;
}
.block-danger {
    background-color: #DB3819;
}
.image-plug {
    min-height: 560px;
    @media(max-width: 1800px) {
        min-height: 485px;
    }
    @media(max-width: 1600px) {
        min-height: 410px;
    }
    @media(max-width: 1400px) {
        min-height: 480px;
    }
    @media(max-width: 1200px) {
        min-height: 510px;
    }
    @media(max-width: 992px) {
        min-height: 560px;
    }
    @media(max-width: 880px) {
        min-height: 480px;
    }
    @media(max-width: 768px) {
        min-height: 415px;
    }
    @media(max-width: 576px) {
        min-height: 270px;
    }
}
.col-1 {
    @include col-w-w1200-w768-w576-fs576(10%, 7%, 12%, 15%);
}
.col-2 {
    @include col-w-w1200-w768-w576-fs576(10%, 7%, 12%, 18%);
}
.col-3 {
    @include col-w-w1200-w768-w576-fs576(80%, 86%, 76%, 67%);
}
.col-1-title {
    @include col-w-w1200-w768-w576-fs576(10%, 7%, 12%, 15%);
}
.col-2-title {
    @include col-w-w1200-w768-w576-fs576(10%, 7%, 12%, 18%);
}
.col-3-title {
    @include col-w-w1200-w768-w576-fs576(80%, 86%, 76%, 67%);
}

.chart-days-weather {
    margin-left: 60px;
    margin-right: 60px;
}
.w-bar-chart {
    padding-right: 115px;
    padding-left: 115px;
    @media(max-width: 2511px) {
        padding-right: 110px;
        padding-left: 110px;
    }
    @media(max-width: 1921px) {
        padding-right: 100px;
        padding-left: 100px;
    }
    @media(max-width: 1601px) {
        padding-right: 95px;
        padding-left: 95px;
    }
    @media(max-width: 1401px) {
        padding-right: 85px;
        padding-left: 85px;
    }
    @media(max-width: 1369px) {
        padding-right: 80px;
        padding-left: 80px;
    }
    @media(max-width: 1201px) {
        padding-right: 72px;
        padding-left: 72px;
    }
    @media(max-width: 993px) {
        padding-right: 75px;
        padding-left: 75px;
    }
    @media(max-width: 799px) {
        padding-right: 75px;
        padding-left: 75px;
    }
}

.modal-gradient {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
}
.zoom-image {
    min-width: 800px;
    overflow: hidden;
    object-fit: contain;
    @media(max-width: 1401px) {
        min-width: 800px;
    }
    @media(max-width: 993px) {
        min-width: 600px;
    }
    @media(max-width: 799px) {
        min-width: 300px;
    }
    
}
.modal-fullscr {
    overflow: hidden !important;
}
