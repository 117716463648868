@mixin col-w-w1200-w768-w576-fs576($width, $width1200, $width768, $width576) {
    width: $width;
    @media(max-width: 1200px) {
        width: $width1200;
    }
    @media(max-width: 768px) {
        width: $width768;
    }
    @media(max-width: 576px) {
        width: $width576;
    }
}
