.field-list {
    &__field {
        color: #ebedef;
        margin: 10px 10px 10px 0px;
        padding: 10px;
        border-radius: 12px;
        background-color: #4f5d73;
    }
}
.card {
    border-radius: 12px;
}
.card-label {
    display: inline-block;
    padding: 10px 24px 10px 12px;
    height: 44px;
    background: #6CAE75;
    border-radius: 10px 4px 40px 10px;

    &__text {
        color: white;
    }
}
.field-list__card-plug {
    min-height: 950px;
    @media(max-width: 1800px) {
        min-height: 880px;
    }
    @media(max-width: 1600px) {
        min-height: 800px;
    }
    @media(max-width: 1400px) {
        min-height: 720px;
    }
    @media(max-width: 1200px) {
        min-height: 900px;
    }
    @media(max-width: 992px) {
        min-height: 750px;
    }
    @media(max-width: 768px) {
        min-height: 665px;
    }
    @media(max-width: 576px) {
        min-height: 665px;
    }
}
