@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

#root {
    font-family: "Roboto", sans-serif;
}
