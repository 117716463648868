@import "../mixins/mixin_reuse";

.dButton {
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 12px;
    line-height: 24px;
    @include fs($common, $common-mobile);
    @include droppity-button($droppity-yellow, $droppity-item-bg, $droppity-yellow);
    &-yellow {
        @include droppity-button(white, $droppity-yellow, $droppity-yellow);
    }
    &-main {
        @include droppity-button($droppity-yellow, $droppity-main-bg, $droppity-yellow);
    }
    &-sm {
        padding: 6px;
        line-height: 18px;
        @include fs($small, $small-mobile);
    }
    &-xsm {
        padding: 2px 4px;
        line-height: 18px;
        @include fs($small, $small-mobile);
    }
    &-outline {
        display: flex;
        padding: 12px;
        align-items: center;
        text-decoration: none;
        border-radius: 12px;
        line-height: 24px;
        @include fs($common, $common-mobile);
        @include droppity-button(white, $droppity-main-bg, $droppity-main-bg);
        border: 1px dashed #484854;
    }
    &-noHover {
        &:hover {
            background-color: unset !important;
        }
        &:active {
            background-color: $droppity-yellow !important;
        }
    }
}
